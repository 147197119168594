@import "../vars";

.ui.container.data-filters-reset {
  margin-left: 0em !important;
  margin-right: 0em !important;

  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 0px 2px;
  background-color: #fff;
  cursor: pointer;
  color: #5baf95;
  font-weight: bold;
  text-transform: lowercase;

  :first-letter{
    text-transform: capitalize !important;
  }

  span {
    float: right;
    font-size: 14px;
  }

  .custom-undo-icon {
    color: #5baf95;
    background-color: #fff;
  }

}

.ui.container.disabled {
  margin-left: 0em !important;
  margin-right: 0em !important;

  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 0px 2px;
  background-color: #fff;
  cursor: pointer;
  color: lightgrey;
  font-weight: bold;
  text-transform: lowercase;

  :first-letter{
    text-transform: capitalize !important;
  }

  span {
    float: right;
    font-size: 14px;
  }

  .custom-undo-icon {
    color: lightgrey;
    background-color: #fff;
  }

}
