.tcdi.download {

  .download-header{
    align-items: center;
    .ui.container.entry-content{
      padding-bottom: $base-unit !important;
      padding-top: $base-unit !important;
    }
  }

  .wp-block-columns{
    margin-bottom: $base-unit + 5;
  }

  .download.area {
    display: flex;
    .ui.container.entry-content{
      padding: 0;
    }
    i.icon.question.circle{
      color: $ng-green;
      cursor: pointer;
      font-size: 0.8em;
      margin-left: 5px;
      padding: 0 0 $base-unit * 2 0;
    }
  }

  .button.download {
    background-color: #55889F;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    z-index: 1000;
    border: 1px solid;
    margin-right: 10px;
  }

  .download-icon {
    align-self: flex-end;
  }

  .download-icon:before {
    top: 1px;
    margin-left: -5px;
    color: #51AB8D;
    border: 1px solid;
    cursor: pointer;
    padding: 7px 9px 9px 9px;
    position: relative;
    z-index: 999;
    background-color: #FFF !important;
    border-radius: 3px;
    cursor: pointer;
    z-index: 90 !important;
  }

  .active {
    .download-icon:before {
      padding-bottom: 17px;
      border-bottom: 0px;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }

  .dropdown.icon {
    display: none;
  }

  .dropdown.download {

    .visible.menu.transition {
      position: absolute;
      left: auto;
      top: 36px;
      right: 9px;
      padding: 15px 20px;
      box-shadow: none;
      border-color: #51AB8D;
      border-radius: 0 !important;
      border-top-left-radius: 3px !important;
      border-bottom-left-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
      font-size: 16px;
      color: #51AB8D;
      font-weight: 600;
      z-index: 80 !important;
    }

    .visible.menu.transition .item {
      padding: 12px 0px 0px 0px !important;
      background: none !important;
    }

    .visible.menu.transition .item input {
      margin-top: 0px;
    }


    input[type=radio] {
      border: 1px solid $gray;
      font-size: 10px;
      height: 14px;
      width: 14px;
      min-height: 6px;
      min-width: 6px;
      margin: 5px 5px 2px 0px;
    }

    input[type=radio]:checked::before {
      content: "";
      background-color: $light-green;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin: 3px;
    }

    label {
      font-size: 16px;
      color: $gray;
    }
  }


}

.tcdi.download.has-title {
  .download.title {
    padding: 0;
    margin: 0px;
    float: left;
    p{
      line-height: 125%;
    }
  }
  .ui.grid, .column {
    padding: 0px;
    margin: 0px;
    .editing {
      margin-top: -1rem !important;
    }

  }
  .ui.grid {
    margin-bottom: -5px;
    margin-top: -5px;
  }
}

.tcdi.download.light {

  .wrapper {
    border: 1px solid #0692fe;
    padding: 3px 5px;
    border-radius: 5px;
    float: right;
  }

  .map.wrapper{
    border:none;
    float: none;
    overflow: hidden;
    @media print{
      margin-left: 20px;
    }
  }

  [data-tooltip]{
    &:after{
      font-size: 0.8rem;
    }
    &:before{
      bottom: $base-unit + 13px;
    }
  }

  .ui.button.download {
    border: 0px;
    font-weight: 300;
    padding: $base-unit / 2;
    background-color: #FFF;
    color: #0692fe;
    margin-right: 0;
    &:hover{
      color: #046fbe;
    }
  }
  .dropdown.download{
    padding-right: $base-unit - 3;
  }
  .download-icon{
    &:before{
      @include border-radius(0);
      border: 0px;
      border-left: 1px solid #0692fe;
      color: #0692fe;
      height: $base-unit * 2;
      margin-left: 5px;
      padding: 0 4px 0 7px;
    }
    &:hover{
      &:before{
        color: #046fbe;
      }
    }
  }

  .dropdown.download {
    .visible.menu.transition {
      border: 1px solid #0692fe;
      top: 30px;
      right: -5px;
      font-weight: 400;
      color: #0692fe;
      border-radius: 5px;
    }
  }
}

.ui.top.left.popup.title-popup{
  background-color: $popup-bg;
  padding: $base-unit * 2;
  &:before{
    background: $popup-bg;
  }
}
