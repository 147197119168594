.inline.list {
  padding: 0px;

.list{
    padding-bottom: 0;
  }

  .ui.grid {
    border-bottom: 1px solid $light-gray;
    padding: 0px 0px 30px 0px;
    margin: 0px 0px 30px 0px;
    &:last-of-type{
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .column {
      padding: 0px;

    }

    .ui.container.entry-content {
      padding: 0px 14px;

    }

    .ui.label {

      img {
        height: auto !important;
        width: 100% !important;
        min-width: 30px;
        margin: 0px;
      }

      height: auto;
      background-color: transparent;
      text-align: center;
      padding: 0px;
      margin: 0px;

    }
  }
  a {
    cursor: pointer;
  }
}
