.wp-block-tcdi-components-newsletter {
  width: 100%;
  padding: 10px;
}

.tcdi.newsLetter {

  text-align: center;
  max-width: 500px;
  height: 60px;
  margin: 1rem auto;

  .ui.negative.message {
    position: relative;
    bottom: -5px;
    right: -546px;
    padding: $base-unit + 5;
    p{
      color: #ffffff;
      line-height: 120%;
    }
  }

  .ui.success.message {
    background-color: #5089a6;
    position: relative;
    bottom: -5px;
    right: -546px;
    padding: $base-unit + 5;
    p{
      color: #ffffff;
      line-height: 120%;
    }
  }

  .tcdi.newsLetter.form {
    padding: 0px;
    height: 42px;
    display: flex;
    justify-content: left;
    margin-bottom: 3px;

    .input {
      flex-grow: 1;
      height: 40px;
    }

    .ui.button {
      height: 40px;
      line-height: 70%;
      margin-left: 10px;
    }

  }
}
